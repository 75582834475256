import React from 'react';
import styles from './Animation.module.scss'

const Animation = () => {
    return (
        <div className={styles.helloContainer}>
            <div className={styles.helloContainer__text}>
                Еб@ть
            </div>
        </div>
    );
};

export default Animation;