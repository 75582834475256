// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__5RBf6 {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.styles_wrapper__5RBf6 > svg {
  width: 100px !important;
  height: 100px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Paint/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AACF;AAAE;EACE,uBAAA;EACA,wBAAA;AAEJ","sourcesContent":[".wrapper {\n  position: absolute;\n  bottom: 10px;\n  right: 10px;\n  > svg {\n    width: 100px !important;\n    height: 100px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__5RBf6`
};
export default ___CSS_LOADER_EXPORT___;
