import React from "react";
import styles from "./Link.module.scss";
import CustomPopup from "./CustomPopup";

const Link = ({ status = false, service }) => {
  const {
    android_url: androidUrl,
    ios_url: iosUrl,
    web_url: webUrl,
    name,
    other_urls: otherUrls,
  } = service || {};

  const shouldShowPopup =
    androidUrl || iosUrl || (otherUrls && Object.keys(otherUrls).length > 0);

  const urlName = name?.charAt(0).toUpperCase() + name?.slice(1);

  return (
    <div className={styles.row}>
      <a href={webUrl} target="_self" rel="noopener noreferrer">
        {urlName}
      </a>
      {shouldShowPopup && (
        <CustomPopup
          iosUrl={iosUrl}
          androidUrl={androidUrl}
          otherUrls={otherUrls}
        />
      )}
      <div className={status ? styles.dotActive : styles.dot} />
    </div>
  );
};

export default Link;
