import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import cx from "classnames";
import styles from "../pages/Wish/Wish.module.scss";
import useCopyToClipboard from "./useCopyToClipboardHook";
import { getWishlist, bookItem, unbookItem } from "../services/wishService";
import paths from "../pages/paths";

export const useWishHook = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [bookingName, setBookingName] = useState("");
  const [localId, setLocalId] = useState(localStorage.getItem("id"));
  const [isHide, setIsHide] = useState(false);

  const fetchWishlist = () => {
    setIsLoading(true);
    return getWishlist(id)
      .then((data) => {
        setData(data);
      })
      .catch(() => {
        navigate(paths.wishlists);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchWishlist();
  }, []);

  const book = (itemId, name) => {
    if (isLoading) return;
    setIsLoading(true);
    return bookItem(itemId, name)
      .then(() => {
        fetchWishlist();
      })
      .catch(() => {
        console.log("err");
      })
      .finally(() => setIsLoading(false));
  };

  const unbook = (itemId) => {
    const isConfirmed = window.confirm(
      "Вы уверены, что хотите отменить бронирование?",
    );
    if (!isConfirmed) {
      return;
    }

    if (isLoading) return;
    setIsLoading(true);

    return unbookItem(itemId)
      .then(() => {
        fetchWishlist();
      })
      .catch(() => {
        console.log("Ошибка при отмене бронирования");
      })
      .finally(() => setIsLoading(false));
  };

  const handleBooking = (itemId, name) => {
    if (name) {
      book(itemId, name);
      setBookingName("");
    }
  };

  const wishClass = cx(styles.wish, {
    [styles.loading]: isLoading,
  });

  const copyToClipboard = useCopyToClipboard();

  const handleSearch = (text) => {
    const query = encodeURIComponent(text);
    const url = `https://www.google.com/search?q=${query}`;
    window.open(url, "_blank");
  };

  const toggleHide = () => setIsHide(!isHide);

  const handleKeyPress = (e, itemId) => {
    if (e.key === "Enter") {
      handleBooking(itemId, bookingName);
    }
  };

  const getWishContentProps = (item) => ({
    data,
    item,
    bookingName,
    setBookingName,
    handleBooking,
    handleSearch,
    unbook,
    copyToClipboard,
    isHide,
    localId,
    handleKeyPress,
  });

  return {
    id,
    navigate,
    toggleHide,
    data,
    wishClass,
    isHide,
    copyToClipboard,
    handleSearch,
    localId,
    bookingName,
    setBookingName,
    handleBooking,
    handleKeyPress,
    unbook,
    getWishContentProps,
  };
};
