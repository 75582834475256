import React from "react";
import styles from "./styles.module.scss";

const SketchfabEmbed = () => {
  // Замените 'yourModelUrl' на URL вашей модели из Sketchfab
  const modelUrl =
    "https://sketchfab.com/models/0d260c87758e48fd8c96579b57a25ed3/embed";

  // Стили для обёртки и iframe можно настроить как угодно
  const wrapperStyles = {
    position: "relative",
    paddingTop: "56.25%" /* 16:9 Aspect Ratio */,
    height: 0,
  };

  const iframeStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: "none",
  };

  const placesObjects = [
    { name: "Crystal Thai food", description: "Тайская жрачка" },
    { name: "Chinese handmade", description: "Чина фуд" },
    { name: "Wanngram", description: "Дорого нахуй" },
    { name: "The Hut Cafe", description: "Тайская жрачка" },
    { name: "Mr. Kai Kitchen", description: "Тайская жрачка" },
    { name: "Sasatorn", description: "Бургеры" },
    { name: "Grow High Fisherman's", description: "Кофешоп жрачный" },
    { name: "Aroy aroy", description: "Русская хавка" },
    { name: "Baannairai farm & cafe", description: "Хуй пойми че" },
    { name: "Spices Cafe", description: "Дорого нахуй" },
    { name: "Nuea butchery", description: "Стейки/карбонара" },
    { name: "Nang Sabai", description: "Мексиканская" },
    { name: "Hit Point Express", description: "Вроде шава" },
    { name: "Чародейка", description: "Русская хавка" },
    { name: "Rossini Ice Cream", description: "Айскрим" },
    { name: "Ban Suan Lung Khai", description: "Хуй пойми че" },
  ];

  const handleSearch = (text) => {
    const query = encodeURIComponent(text);
    const url = `https://www.google.com/maps/search/?api=1&query=${query}`;
    window.open(url, "_blank");
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: "white",
          gap: 6,
          minWidth: "max-content",
          maxWidth: "max-content",
          padding: 12,
          borderRadius: 8,
        }}
      >
        {placesObjects.map((item, index) => (
          <div
            style={{ display: "flex", gap: 8, flexWrap: "wrap", maxWidth: 350 }}
            onClick={() => handleSearch(`${item.name} Samui`)}
          >
            <span>{`${index + 1}.`}</span>
            <span className={styles.name}>{item.name}</span>
            {item.description && (
              <span style={{ color: "gray" }}>{`(${item.description})`}</span>
            )}
          </div>
        ))}
      </div>
    </div>
    // <div style={wrapperStyles}>
    //   <iframe
    //     src={modelUrl}
    //     title="3D model"
    //     allowFullScreen
    //     mozallowfullscreen="true"
    //     webkitallowfullscreen="true"
    //     style={iframeStyles}
    //     allow="autoplay; fullscreen; xr-spatial-tracking"
    //     xr-spatial-tracking
    //     execution-while-out-of-viewport
    //     execution-while-not-rendered
    //     web-share
    //   ></iframe>
    // </div>
  );
};

export default SketchfabEmbed;
