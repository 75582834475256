import { useEffect, useState } from "react";
import paths from "../pages/paths";
import useNavigation from "./useNavigation";
import {
  getWishlists,
  createWishlist,
  updateWishlist,
  updateWishlistName,
  deleteWishlist,
  deleteWish,
  updateWishItem as updateWishItemService,
} from "../services/wishlistsService";
import { getMe } from "../services/mainPageService";

export const useWishlistsHook = () => {
  const { redirectTo } = useNavigation();
  const [id, setId] = useState(localStorage.getItem("id"));
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newWish, setNewWish] = useState("");
  const [wishListIdToUpdate, setWishListIdToUpdate] = useState(null);
  const [newWishListName, setNewWishListName] = useState("");
  const [selectedWishlist, setSelectedWishlist] = useState(null);

  useEffect(() => {
    if (!id) {
      fetchMe();
    } else {
      fetchWishlists();
    }
  }, [id]);

  const redirectToLogin = () => {
    redirectTo(paths.login);
  };

  const fetchMe = () => {
    getMe()
      .then((data) => {
        localStorage.setItem("id", data.id);
        setId(data.id);
        fetchWishlists();
      })
      .catch(redirectToLogin);
  };

  const fetchWishlists = () => {
    if (!isLoading) setIsLoading(true);
    return getWishlists()
      .then((data) => {
        setData(data);
        return data;
      })
      .catch(redirectToLogin)
      .finally(() => setIsLoading(false));
  };

  const createNewWishlist = (isPrivate) => () => {
    setIsLoading(true);
    createWishlist(isPrivate)
      .then(fetchWishlists)
      .finally(() => setIsLoading(false));
  };

  const updateExistingWishlist = () => {
    if (!newWish.trim() || !wishListIdToUpdate) return;

    setIsLoading(true);
    updateWishlist(wishListIdToUpdate, newWish)
      .then(() => {
        setNewWish("");
        setWishListIdToUpdate(null);
        fetchWishlists().then((newData) => {
          if (selectedWishlist) {
            const updatedWishlist = newData.find(
              (wishlist) => wishlist.id === selectedWishlist.id
            );
            setSelectedWishlist(updatedWishlist);
          }
        });
      })
      .finally(() => setIsLoading(false));
  };

  const updateWishlistNameHandler = () => {
    if (!newWishListName.trim()) return;

    setIsLoading(true);
    updateWishlistName(wishListIdToUpdate, newWishListName)
      .then(() => {
        setNewWishListName("");
        fetchWishlists().then((newData) => {
          if (selectedWishlist) {
            const updatedWishlist = newData.find(
              (wishlist) => wishlist.id === selectedWishlist.id
            );
            setSelectedWishlist(updatedWishlist);
          }
        });
      })
      .finally(() => setIsLoading(false));
  };

  const deleteExistingWishlist = (wishlistId) => {
    const isConfirmed = window.confirm(
      "Вы уверены, что хотите удалить вишлист?"
    );
    if (!isConfirmed) return;

    setIsLoading(true);
    deleteWishlist(wishlistId)
      .then(() => {
        fetchWishlists();
        if (selectedWishlist) setSelectedWishlist(null);
      })
      .finally(() => setIsLoading(false));
  };

  const deleteExistingWish = (wishlistId) => {
    setIsLoading(true);
    deleteWish(wishlistId)
      .then(() => {
        fetchWishlists().then((newData) => {
          if (selectedWishlist) {
            const updatedWishlist = newData.find(
              (wishlist) => wishlist.id === selectedWishlist.id
            );
            setSelectedWishlist(updatedWishlist);
          }
        });
      })
      .finally(() => setIsLoading(false));
  };

  const openWishlist = (id) => {
    const wishPath = paths.wishlist.replace(":id", id);
    const fullUrl = window.location.origin + wishPath;
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        console.log("URL copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
    redirectTo(wishPath);
  };

  const isEdit = (el) => {
    return id?.toString() === el.user_id?.toString();
  };

  const updateWishItem = (itemId, data) => {
    setIsLoading(true);
    return updateWishItemService(itemId, data)
      .then(() => {
        fetchWishlists().then((newData) => {
          if (selectedWishlist) {
            const updatedWishlist = newData.find(
              (wishlist) => wishlist.id === selectedWishlist.id
            );
            setSelectedWishlist(updatedWishlist);
          }
        });
      })
      .finally(() => setIsLoading(false));
  };

  const getListProps = (el) => ({
    deleteExistingWish,
    deleteExistingWishlist,
    updateExistingWishlist,
    updateWishlistNameHandler,
    openWishlist,
    updateWishItem,
    el,
    isEdit,
    newWish,
    newWishListName,
    setNewWishListName,
    setNewWish,
    wishListIdToUpdate,
    setWishListIdToUpdate,
    isLoading,
  });

  const handleWishlistClick = (wishlist) => {
    setSelectedWishlist(wishlist);
  };

  const handleBack = () => {
    setSelectedWishlist(null);
  };

  return {
    isLoading,
    data,
    createNewWishlist,
    getListProps,
    selectedWishlist,
    handleWishlistClick,
    handleBack,
  };
};
