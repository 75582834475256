import React from "react";
import styles from "./styles.module.scss";
import animationData from "../../paint.json";
import Lottie from "lottie-react";

const Paint = ({ onClick }) => {
  return (
    <Lottie
      onClick={onClick}
      className={styles.wrapper}
      animationData={animationData}
      loop={true}
    />
  );
};

export default Paint;
