// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_loginForm__1kk93 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.Login_loginForm__1kk93 > form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.Login_loginForm__submit__fmAKm {
  font-size: 16px;
  color: black;
  margin: 10px;
  padding: 8px 16px;
  border-radius: 10px;
  font-family: "RubikMoonrocks", sans-serif;
  background-color: white;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.3), 0 1px 3px rgba(255, 255, 255, 0.2);
  transition: transform 0.1s ease, background-color 0.2s ease;
}
.Login_loginForm__submit__fmAKm:hover {
  box-shadow: 0 10px 15px rgba(255, 255, 255, 0.4), 0 3px 6px rgba(255, 255, 255, 0.3), 0 2px 4px rgba(255, 255, 255, 0.2);
}
.Login_loginForm__submit__fmAKm:active {
  transform: scale(0.95);
}

.Login_input__SXjTA {
  border: unset;
  font-size: 16px;
  outline: none;
  padding: 8px;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.3), 0 1px 3px rgba(255, 255, 255, 0.2);
}
.Login_input__SXjTA:hover {
  box-shadow: 0 10px 15px rgba(255, 255, 255, 0.4), 0 3px 6px rgba(255, 255, 255, 0.3), 0 2px 4px rgba(255, 255, 255, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAEE;EACE,eAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,yCAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,kFAAA;EAEA,2DAAA;AADJ;AAGI;EACE,wHAAA;AADN;AAMI;EACE,sBAAA;AAJN;;AAUA;EACE,aAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,kFAAA;AAPF;AAUE;EACE,wHAAA;AARJ","sourcesContent":[".loginForm {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n\n  > form {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n  }\n\n  &__submit {\n    font-size: 16px;\n    color: black;\n    margin: 10px;\n    padding: 8px 16px;\n    border-radius: 10px;\n    font-family: 'RubikMoonrocks', sans-serif;\n    background-color: white;\n    border: none;\n    cursor: pointer;\n    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.3),\n    0 1px 3px rgba(255, 255, 255, 0.2);\n    transition: transform 0.1s ease, background-color 0.2s ease;\n\n    &:hover {\n      box-shadow: 0 10px 15px rgba(255, 255, 255, 0.4),\n      0 3px 6px rgba(255, 255, 255, 0.3),\n      0 2px 4px rgba(255, 255, 255, 0.2);\n    }\n\n    &:active {\n      transform: scale(0.95);\n    }\n  }\n\n}\n\n.input {\n  border: unset;\n  font-size: 16px;\n  outline: none;\n  padding: 8px;\n  border-radius: 10px;\n  transition: all 0.3s ease;\n  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.3),\n  0 1px 3px rgba(255, 255, 255, 0.2);\n\n  &:hover {\n    box-shadow: 0 10px 15px rgba(255, 255, 255, 0.4),\n    0 3px 6px rgba(255, 255, 255, 0.3),\n    0 2px 4px rgba(255, 255, 255, 0.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginForm": `Login_loginForm__1kk93`,
	"loginForm__submit": `Login_loginForm__submit__fmAKm`,
	"input": `Login_input__SXjTA`
};
export default ___CSS_LOADER_EXPORT___;
