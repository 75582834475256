// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainPage_wrapper__SQ2nS {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(30px + 2vmin);
  color: white;
}

.MainPage_links__uMVSf {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/MainPage.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,6BAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;AACF","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-size: calc(30px + 2vmin);\n  color: white;\n}\n\n.links {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MainPage_wrapper__SQ2nS`,
	"links": `MainPage_links__uMVSf`
};
export default ___CSS_LOADER_EXPORT___;
