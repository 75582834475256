// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  background-color: #282c34;
}

#root, html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --pull-to-refresh-background-color: #282c34;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  text-decoration: none;
  color: inherit;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
}

a:hover {
  text-decoration: underline;
}


[role='tooltip'].popup-content {
  width: min-content !important;
  border-radius: 8px !important;
  cursor: pointer;
}

@supports (background-color: var(--pull-to-refresh-background-color)) {
  ._refresh {
    background-color: var(--pull-to-refresh-background-color);
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,2CAA2C;EAC3C,SAAS;EACT;;YAEU;EACV,mCAAmC;EACnC,kCAAkC;EAClC,2CAA2C;AAC7C;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;WACS;AACX;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,yBAAyB;EAGzB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,0BAA0B;AAC5B;;;AAGA;EACE,6BAA6B;EAC7B,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE;IACE,yDAAyD;EAC3D;AACF","sourcesContent":["html, body {\n  background-color: #282c34;\n}\n\n#root, html {\n  height: 100%;\n  width: 100%;\n}\n\nbody {\n  height: 100%;\n  box-sizing: border-box;\n  padding-bottom: env(safe-area-inset-bottom);\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n  sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  --pull-to-refresh-background-color: #282c34;\n}\n\n* {\n  box-sizing: border-box;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n  monospace;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  outline: none;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\n\n[role='tooltip'].popup-content {\n  width: min-content !important;\n  border-radius: 8px !important;\n  cursor: pointer;\n}\n\n@supports (background-color: var(--pull-to-refresh-background-color)) {\n  ._refresh {\n    background-color: var(--pull-to-refresh-background-color);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
