import { axiosInstance } from "./axiosService";

const handleResponse = (response) => {
  if (response && response.data && response.data.data) {
    return response.data.data;
  }
  throw new Error("Invalid response structure");
};

export const getWishlists = () => {
  return axiosInstance
    .get("/wishlists")
    .then(handleResponse)
    .catch((error) => {
      console.error("Error fetching wishlists:", error);
      throw error;
    });
};

export const createWishlist = (isPrivate) => {
  return axiosInstance
    .post("/wishlists", { wishlist: { private: isPrivate } })
    .then(handleResponse)
    .catch((error) => {
      console.error("Error creating wishlist:", error);
      throw error;
    });
};

export const updateWishlist = (wishListIdToUpdate, newWish) => {
  return axiosInstance
    .post(`/wishlist_items`, {
      wishlistItem: {
        wishlistId: wishListIdToUpdate,
        wish: newWish,
      },
    })
    .then(handleResponse)
    .catch((error) => {
      console.error("Error updating wishlist:", error);
      throw error;
    });
};

export const updateWishlistName = (wishListIdToUpdate, newWishListName) => {
  return axiosInstance
    .put(`/wishlists/${wishListIdToUpdate}`, {
      wishlist: {
        name: newWishListName,
      },
    })
    .then(handleResponse)
    .catch((error) => {
      console.error("Error updating wishlist name:", error);
      throw error;
    });
};

export const deleteWishlist = (wishlistId) => {
  return axiosInstance.delete(`/wishlists/${wishlistId}`).catch((error) => {
    console.error("Error deleting wishlist:", error);
    throw error;
  });
};

export const deleteWish = (wishlistId) => {
  return axiosInstance
    .delete(`/wishlist_items/${wishlistId}`)
    .catch((error) => {
      console.error("Error deleting wish:", error);
      throw error;
    });
};

export const updateWishItem = (itemId, data) => {
  return axiosInstance
    .patch(`/wishlist_items/${itemId}`, { wishlist_item: data })
    .then(handleResponse)
    .catch((error) => {
      console.error("Error updating wish item:", error);
      throw error;
    });
};
