export const InfoIcon = ({ className, onClick }) => {
  return (
    <svg
      cursor={"pointer"}
      width={22}
      height={22}
      className={className}
      onClick={onClick}
      viewBox={"0 0 10 10"}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <g>
        <title>Layer 1</title>
        <g stroke="null" id="svg_1">
          <path
            id="svg_2"
            fill="#000000"
            d="m4.96108,0c-2.73457,0 -4.96108,2.22353 -4.96108,4.95844c0,2.73491 2.2265,4.96247 4.96108,4.96247c2.73459,0.00002 4.9619,-2.22756 4.9619,-4.96247c0,-2.73491 -2.22731,-4.95845 -4.9619,-4.95844zm0,0.82358c2.28781,0 4.13519,1.84678 4.13519,4.13486c0,2.28807 -1.84738,4.13567 -4.13519,4.13567c-2.28781,0 -4.13437,-1.8476 -4.13437,-4.13567c0,-2.28807 1.84657,-4.13486 4.13437,-4.13486zm0,1.24182a0.41335,0.4134 0 0 0 -0.41335,0.41342a0.41335,0.4134 0 0 0 0.41335,0.4134a0.41335,0.4134 0 0 0 0.41337,-0.4134a0.41335,0.4134 0 0 0 -0.41337,-0.41342zm0,1.65443a0.41335,0.4134 0 0 0 -0.41496,0.41179l0,3.30806a0.41335,0.4134 0 0 0 0.41496,0.41179a0.41335,0.4134 0 0 0 0.41255,-0.41179l0,-3.30806a0.41335,0.4134 0 0 0 -0.41255,-0.41179z"
          />
        </g>
      </g>
    </svg>
  );
};
export const CheckIcon = ({ onClick }) => (
  <svg
    onClick={onClick}
    cursor={"pointer"}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 12L11 14L15 10"
      stroke="#32CD32"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="12" cy="12" r="10" stroke="#32CD32" strokeWidth="2" />
  </svg>
);

export const CrossIcon = ({ onClick }) => (
  <svg
    onClick={onClick}
    cursor={"pointer"}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke="#FF0000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="#FF0000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="12" cy="12" r="10" stroke="#FF0000" strokeWidth="2" />
  </svg>
);
