// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Title_blink__mwaBk {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.Title_blink__mwaBk span {
  display: inline-block;
  animation: Title_blink__mwaBk 4s infinite;
}

.Title_blink__mwaBk span:nth-child(1) {
  animation-delay: 0s;
}

.Title_blink__mwaBk span:nth-child(2) {
  animation-delay: 0.8s;
}

.Title_blink__mwaBk span:nth-child(3) {
  animation-delay: 1.6s;
}

.Title_blink__mwaBk span:nth-child(4) {
  animation-delay: 2.4s;
}

.Title_blink__mwaBk span:nth-child(5) {
  animation-delay: 3.2s;
}

.Title_blink__mwaBk span:nth-child(6) {
  animation: unset;
}`, "",{"version":3,"sources":["webpack://./src/components/Title/Title.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF;AAEA;EACE,qBAAA;EACA,yCAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAEA;EACE,gBAAA;AACF","sourcesContent":["@keyframes blink {\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0;\n  }\n}\n\n.blink span {\n  display: inline-block;\n  animation: blink 4s infinite;\n}\n\n.blink span:nth-child(1) {\n  animation-delay: 0s;\n}\n\n.blink span:nth-child(2) {\n  animation-delay: 0.8s;\n}\n\n.blink span:nth-child(3) {\n  animation-delay: 1.6s;\n}\n\n.blink span:nth-child(4) {\n  animation-delay: 2.4s;\n}\n\n.blink span:nth-child(5) {\n  animation-delay: 3.2s;\n}\n.blink span:nth-child(6) {\n  animation: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blink": `Title_blink__mwaBk`
};
export default ___CSS_LOADER_EXPORT___;
