// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Link_row__uPZvA {
  display: flex;
  align-items: center;
  position: relative;
  gap: 16px;
}

@keyframes Link_blink__kIxLG {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.Link_dot__bZLsz,
.Link_dotActive__2ltnl {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.Link_dotActive__2ltnl {
  background-color: #07f013;
}

.Link_dot__bZLsz {
  animation: Link_blink__kIxLG 2s linear infinite;
  background-color: gray;
}

.Link_downloadIcon__PyqPm {
  display: flex;
  cursor: pointer;
  position: absolute;
  left: -32px;
}
.Link_downloadIcon__PyqPm > svg {
  width: 25px;
  height: 23px;
}

.Link_iconsRow__vxnJb {
  display: flex;
  gap: 20px;
  width: min-content;
}

.Link_link__j3yA7 {
  width: 30px;
  height: 30px;
}
.Link_link__j3yA7:hover {
  box-shadow: 0 10px 15px rgba(255, 255, 255, 0.4), 0 3px 6px rgba(255, 255, 255, 0.3), 0 2px 4px rgba(255, 255, 255, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/components/Link/Link.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;AACF;;AAEA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;AACF;AAEA;;EAEE,WAAA;EACA,YAAA;EACA,kBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,+CAAA;EACA,sBAAA;AAAF;;AAGA;EACE,aAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AAAF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;;AAKA;EACE,aAAA;EACA,SAAA;EACA,kBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;AAFF;AAGE;EACE,wHAAA;AADJ","sourcesContent":[".row {\n  display: flex;\n  align-items: center;\n  position: relative;\n  gap: 16px;\n}\n\n@keyframes blink {\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0;\n  }\n}\n\n.dot,\n.dotActive {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n}\n\n.dotActive {\n  background-color: #07f013;\n}\n\n.dot {\n  animation: blink 2s linear infinite;\n  background-color: gray;\n}\n\n.downloadIcon {\n  display: flex;\n  cursor: pointer;\n  position: absolute;\n  left: -32px;\n\n  > svg {\n    width: 25px;\n    height: 23px;\n  }\n\n}\n\n.iconsRow {\n  display: flex;\n  gap: 20px;\n  width: min-content;\n}\n\n.link {\n  width: 30px;\n  height: 30px;\n  &:hover {\n    box-shadow: 0 10px 15px rgba(255, 255, 255, 0.4),\n    0 3px 6px rgba(255, 255, 255, 0.3),\n    0 2px 4px rgba(255, 255, 255, 0.2);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `Link_row__uPZvA`,
	"dot": `Link_dot__bZLsz`,
	"dotActive": `Link_dotActive__2ltnl`,
	"blink": `Link_blink__kIxLG`,
	"downloadIcon": `Link_downloadIcon__PyqPm`,
	"iconsRow": `Link_iconsRow__vxnJb`,
	"link": `Link_link__j3yA7`
};
export default ___CSS_LOADER_EXPORT___;
