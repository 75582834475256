import React, { useState, useEffect, useRef } from "react";
import { fabric } from "fabric";

const Paint = () => {
  const [brushColor, setBrushColor] = useState("#000000");
  const [brushRadius, setBrushRadius] = useState(4);
  const [canvas, setCanvas] = useState(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const fabricCanvas = new fabric.Canvas(canvasRef.current, {
      isDrawingMode: true,
      backgroundColor: "#ffffff",
    });
    fabricCanvas.freeDrawingBrush.color = brushColor;
    fabricCanvas.freeDrawingBrush.width = brushRadius;
    setCanvas(fabricCanvas);

    return () => {
      fabricCanvas.dispose();
    };
  }, []);

  useEffect(() => {
    if (canvas) {
      canvas.freeDrawingBrush.color = brushColor;
      canvas.freeDrawingBrush.width = brushRadius;
    }
  }, [brushColor, brushRadius, canvas]);

  const addRectangle = () => {
    const rect = new fabric.Rect({
      left: 100,
      top: 100,
      fill: "red",
      width: 60,
      height: 70,
      selectable: true,
    });
    canvas.add(rect);
  };

  const addCircle = () => {
    const circle = new fabric.Circle({
      left: 200,
      top: 100,
      fill: "green",
      radius: 50,
      selectable: true,
    });
    canvas.add(circle);
  };

  const toggleDrawingMode = () => {
    canvas.isDrawingMode = !canvas.isDrawingMode;
  };

  const changeSelectedColor = () => {
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      activeObject.set("fill", brushColor);
      canvas.renderAll();
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "10px",
          background: "#eee",
        }}
      >
        <input
          type="color"
          value={brushColor}
          onChange={(e) => setBrushColor(e.target.value)}
        />
        <input
          type="range"
          min="1"
          max="20"
          value={brushRadius}
          onChange={(e) => setBrushRadius(parseInt(e.target.value, 10))}
        />
        <button onClick={() => canvas.clear()}>Clear</button>
        <button onClick={() => canvas.undo()}>Undo</button>
        <button onClick={addRectangle}>Add Rectangle</button>
        <button onClick={addCircle}>Add Circle</button>
        <button onClick={toggleDrawingMode}>
          {canvas?.isDrawingMode
            ? "Switch to Select Mode"
            : "Switch to Draw Mode"}
        </button>
        <button onClick={changeSelectedColor}>Change Selected Color</button>
      </div>
      <div style={{ flex: 1 }}>
        <canvas
          ref={canvasRef}
          width={window.innerWidth}
          height={window.innerHeight}
        />
      </div>
    </div>
  );
};

export default Paint;
