import { useEffect, useState } from "react";
import { getMe, getServices } from "../services/mainPageService";
import paths from "../pages/paths";
import useNavigation from "./useNavigation";

export const useMainPageHook = () => {
  const [services, setServices] = useState([]);
  const [statuses, setStatuses] = useState({});
  const [isAnimation, setIsAnimation] = useState(true);
  const { redirectTo } = useNavigation();

  useEffect(() => {
    fetchInitialData();
    const timer = setTimeout(() => {
      setIsAnimation(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const fetchInitialData = () => {
    getMe()
      .then(getServices)
      .then(({ services, initialStatuses }) => {
        setServices(services);
        setStatuses(initialStatuses);
      })
      .catch(redirectToLogin);
  };

  const redirectToLogin = () => {
    if (localStorage.getItem("id")) {
      localStorage.removeItem("id");
    }
    redirectTo(paths.login);
  };

  const redirectToPaint = () => redirectTo(paths.paint);

  const serviceData = services.map((service) => ({
    ...service,
    status: statuses[service.name],
  }));

  const getLinksProps = (item) => ({
    service: item,
    status: statuses[item.name],
  });

  return {
    statuses,
    services,
    isAnimation,
    redirectToPaint,
    serviceData,
    getLinksProps,
  };
};
