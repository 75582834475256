import React, { useState } from "react";
import styles from "./Live.module.scss";

const Live = () => {
  const [url, setUrl] = useState("");
  return (
    <div className={styles.wrapper}>
      <div className={styles.url}>
        <p>{`https://restreamer.ebat.me/${url}`}</p>
        <input value={url} onChange={(e) => setUrl(e.target.value)} />
      </div>
      <iframe
        src={`https://restreamer.ebat.me/${url}`}
        width="100%"
        height="100%"
        frameBorder="no"
        scrolling="no"
        allowFullScreen="true"
      />
    </div>
  );
};

export default Live;
