import { useCallback } from "react";
import { NotificationManager } from "react-notifications";

const useCopyToClipboard = () => {
  return useCallback((text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationManager.success("Скопировано", "", 3000);
      })
      .catch(() => {
        NotificationManager.error("Ошибка при копировании", "", 3000);
      });
  }, []);
};

export default useCopyToClipboard;
