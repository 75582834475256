import React from "react";
import styles from "./Wishlists.module.scss";

const List = ({
  wishlistClass,
  el,
  isEdit,
  wishListIdToUpdate,
  newWishListName,
  setWishListIdToUpdate,
  setNewWishListName,
  isLoading,
  updateWishlistNameHandler,
  deleteExistingWish,
  newWish,
  setNewWish,
  updateExistingWishlist,
  deleteExistingWishlist,
  openWishlist,
  copyToClipboard,
}) => {
  return (
    <div className={wishlistClass}>
      <span>{`${el?.user_name || "Анон"} ${el?.private ? "🌚" : ""}`}</span>
      <span>{el?.name || ""}</span>
      <div className={styles.input}>
        {isEdit(el) && (
          <input
            type="text"
            placeholder="Новое имя вишлиста"
            value={wishListIdToUpdate === el.id ? newWishListName : ""}
            onChange={(e) => {
              setWishListIdToUpdate(el.id);
              setNewWishListName(e.target.value);
            }}
            disabled={isLoading}
          />
        )}
        {isEdit(el) && (
          <button
            onClick={() => updateWishlistNameHandler(el.id)}
            disabled={isLoading || !newWishListName.trim()}
          >
            Изменить имя
          </button>
        )}
      </div>
      <div className={styles.column}>
        {el.items.data.map((item, index) => (
          <div className={styles.row} key={index}>
            <span onClick={() => copyToClipboard(item.wish)}>{item.wish}</span>
            {isEdit(el) && (
              <button
                onClick={() => deleteExistingWish(item.id, item.wish)}
                disabled={isLoading}
              >
                X
              </button>
            )}
          </div>
        ))}
      </div>
      <div className={styles.shareButtonColumn}>
        <div className={styles.input}>
          {isEdit(el) && (
            <input
              type="text"
              placeholder="Введите ваш виш"
              value={wishListIdToUpdate === el.id ? newWish : ""}
              onChange={(e) => {
                setWishListIdToUpdate(el.id);
                setNewWish(e.target.value);
              }}
              disabled={isLoading}
            />
          )}
          {isEdit(el) && (
            <button
              onClick={updateExistingWishlist}
              disabled={isLoading || !newWish.trim()}
            >
              +
            </button>
          )}
        </div>
        <div className={styles.shareButtonRow}>
          {isEdit(el) && (
            <button
              onClick={() => deleteExistingWishlist(el.id)}
              disabled={isLoading}
            >
              Удалить
            </button>
          )}
          <button onClick={() => openWishlist(el.id)} disabled={isLoading}>
            Поделиться
          </button>
        </div>
      </div>
    </div>
  );
};

export default List;
