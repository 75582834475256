import React from "react";
import styles from "./Wish.module.scss";
import Popup from "reactjs-popup";
import { CheckIcon, CrossIcon, InfoIcon } from "../../components/icons";

const WishActions = ({
  item,
  bookingName,
  setBookingName,
  handleBooking,
  unbook,
  handleKeyPress,
}) => {
  return (
    <div className={styles.actions}>
      {!item.booked && (
        <Popup
          className={styles.color}
          trigger={
            <div>
              <CheckIcon />
            </div>
          }
          position="left center"
        >
          <div className={styles.popup}>
            <input
              type="text"
              value={bookingName}
              onChange={(e) => setBookingName(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e, item.id)}
              className={styles.input}
              placeholder="Введите имя"
            />
            <button
              className={styles.button}
              onClick={() => handleBooking(item.id, bookingName)}
            >
              Добавить
            </button>
          </div>
        </Popup>
      )}
      {!!item.booked && <CrossIcon onClick={() => unbook(item.id)} />}
      {!!item.booked && (
        <div className={styles.info}>
          <InfoIcon
            onClick={() => alert(`Забронировано by ${item.booked_by_name}`)}
          />
        </div>
      )}
    </div>
  );
};

export default WishActions;
