import React from "react";
import paths from "../../pages/paths";
import styles from "./Title.module.scss";
import useNavigation from "../../hooks/useNavigation";

const Title = () => {
  const { redirectTo } = useNavigation();
  const redirectToWishlists = () => redirectTo(paths.wishlists);
  return (
    <p onClick={redirectToWishlists} className={styles.blink}>
      <span>Е</span>
      <span>б</span>
      <span>@</span>
      <span>т</span>
      <span>ь</span>
    </p>
  );
};

export default Title;
