// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Animation_helloContainer__ifO8k {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 5em;
}
.Animation_helloContainer__text__IzGWO {
  animation: Animation_fadeInOut__h5U9k 1s ease-in-out forwards;
}
@keyframes Animation_fadeInOut__h5U9k {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Animation/Animation.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,cAAA;AACF;AACE;EACE,6DAAA;AACJ;AAEE;EACE;IAAW,UAAA;EACb;EAAE;IAAM,UAAA;EAGR;AACF","sourcesContent":[".helloContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100vw;\n  position: fixed;\n  top: 0;\n  left: 0;\n  font-size: 5em;\n\n  &__text {\n    animation: fadeInOut 1s ease-in-out forwards;\n  }\n\n  @keyframes fadeInOut {\n    0%, 100% { opacity: 0; }\n    50% { opacity: 1; }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helloContainer": `Animation_helloContainer__ifO8k`,
	"helloContainer__text": `Animation_helloContainer__text__IzGWO`,
	"fadeInOut": `Animation_fadeInOut__h5U9k`
};
export default ___CSS_LOADER_EXPORT___;
