import React from "react";
import styles from "./MainPage.module.scss";
import Link from "../../components/Link/Link";
import { useMainPageHook } from "../../hooks/useMainPageHook";
import Animation from "../../components/Animation/Animation";
import Title from "../../components/Title/Title";
import DataRenderer from "../../components/DataRenderer/DataRenderer";
import Anime from "../Animation/Anime";
import Paint from "../../components/Paint/Paint";

const MainPage = () => {
  const { isAnimation, redirectToPaint, serviceData, getLinksProps } =
    useMainPageHook();

  if (isAnimation) {
    return <Animation />;
  }

  return (
    <div className={styles.wrapper}>
      <Title />
      <Paint onClick={redirectToPaint} />
      <div className={styles.links}>
        <DataRenderer
          data={serviceData}
          Component={Link}
          getProps={getLinksProps}
        />
      </div>
      <Anime />
    </div>
  );
};

export default MainPage;
