import React, { useState } from "react";
import CanvasDraw from "react-canvas-draw";

const Paint = () => {
  const [brushColor, setBrushColor] = useState("#000000");
  const [brushRadius, setBrushRadius] = useState(4);
  const [canvas, setCanvas] = useState(null);
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "10px",
          background: "#eee",
        }}
      >
        <input
          type="color"
          value={brushColor}
          onChange={(e) => setBrushColor(e.target.value)}
        />
        <input
          type="range"
          min="1"
          max="20"
          value={brushRadius}
          onChange={(e) => setBrushRadius(parseInt(e.target.value, 10))}
        />
        <button onClick={() => canvas.clear()}>Clear</button>
        <button onClick={() => canvas.undo()}>Undo</button>
      </div>
      <div style={{ flex: 1 }}>
        <CanvasDraw
          ref={(canvasDraw) => setCanvas(canvasDraw)}
          style={{ width: "100%", height: "100%" }}
          brushColor={brushColor}
          brushRadius={brushRadius}
          canvasWidth={window.innerWidth}
          canvasHeight={window.innerHeight}
        />
      </div>
    </div>
  );
};

export default Paint;
