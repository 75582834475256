import { useState, useEffect } from "react";
import paths from "../pages/paths";
import classnames from "classnames";
import styles from "../pages/Wishists/Wishlists.module.scss";
import useCopyToClipboard from "./useCopyToClipboardHook";
import useNavigation from "./useNavigation";
import {
  getWishlists,
  createWishlist,
  updateWishlist,
  updateWishlistName,
  deleteWishlist,
  deleteWish,
} from "../services/wishlistsService";
import { getMe } from "../services/mainPageService";

export const useWishlistsHook = () => {
  const { redirectTo } = useNavigation();
  const [id, setId] = useState(localStorage.getItem("id"));
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newWish, setNewWish] = useState("");
  const [wishListIdToUpdate, setWishListIdToUpdate] = useState(null);
  const [newWishListName, setNewWishListName] = useState("");
  const [isHide, setIsHide] = useState(true);
  const copyToClipboard = useCopyToClipboard();

  useEffect(() => {
    if (!id) {
      fetchMe();
    } else {
      fetchWishlists();
    }
  }, [id]);

  const redirectToLogin = () => {
    redirectTo(paths.login);
  };

  const fetchMe = () => {
    getMe()
      .then((data) => {
        localStorage.setItem("id", data.id);
        setId(data.id);
        fetchWishlists();
      })
      .catch(redirectToLogin);
  };

  const fetchWishlists = () => {
    setIsLoading(true);
    getWishlists()
      .then((data) => {
        setData(data);
      })
      .catch(redirectToLogin)
      .finally(() => setIsLoading(false));
  };

  const createNewWishlist = (isPrivate) => () => {
    setIsLoading(true);
    createWishlist(isPrivate)
      .then(fetchWishlists)
      .finally(() => setIsLoading(false));
  };

  const updateExistingWishlist = () => {
    if (!newWish.trim() || !wishListIdToUpdate) return;

    setIsLoading(true);
    updateWishlist(wishListIdToUpdate, newWish)
      .then(() => {
        setNewWish("");
        setWishListIdToUpdate(null);
        fetchWishlists();
      })
      .finally(() => setIsLoading(false));
  };

  const updateWishlistNameHandler = () => {
    if (!newWishListName.trim()) return;

    setIsLoading(true);
    updateWishlistName(wishListIdToUpdate, newWishListName)
      .then(() => {
        setNewWishListName("");
        fetchWishlists();
      })
      .finally(() => setIsLoading(false));
  };

  const deleteExistingWishlist = (wishlistId) => {
    const isConfirmed = window.confirm(
      "Вы уверены, что хотите удалить вишлист?",
    );
    if (!isConfirmed) return;

    setIsLoading(true);
    deleteWishlist(wishlistId)
      .then(fetchWishlists)
      .finally(() => setIsLoading(false));
  };

  const deleteExistingWish = (wishlistId) => {
    setIsLoading(true);
    deleteWish(wishlistId).then(fetchWishlists);
  };

  const openWishlist = (id) => {
    const wishPath = paths.wishlist.replace(":id", id);
    const fullUrl = window.location.origin + wishPath;
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        console.log("URL copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
    redirectTo(wishPath);
  };

  const isEdit = (el) => {
    return id?.toString() === el.user_id?.toString();
  };

  const toggleHide = () => setIsHide(!isHide);

  const wishlistClass = classnames(styles.wish, {
    [styles.hide]: isHide,
  });

  const getListProps = (el) => ({
    deleteExistingWish,
    deleteExistingWishlist,
    updateExistingWishlist,
    updateWishlistNameHandler,
    openWishlist,
    wishlistClass,
    el,
    isEdit,
    newWish,
    newWishListName,
    setNewWishListName,
    setNewWish,
    wishListIdToUpdate,
    setWishListIdToUpdate,
    isLoading,
    copyToClipboard,
  });

  return {
    toggleHide,
    isLoading,
    data,
    createNewWishlist,
    getListProps,
  };
};
