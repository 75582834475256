import { axiosInstance } from "./axiosService";

const handleResponse = (response) => {
  if (response && response.data && response.data.data) {
    return response.data.data;
  }
  throw new Error("Invalid response structure");
};

export const getWishlist = (id) => {
  return axiosInstance
    .get(`/wishlists/${id}`)
    .then(handleResponse)
    .catch((error) => {
      console.error("Error fetching wishlist:", error);
      throw error;
    });
};

export const bookItem = (itemId, name) => {
  return axiosInstance
    .post(`/wishlist_items/${itemId}/book`, {
      wishlistItem: { bookedByName: name },
    })
    .then(handleResponse)
    .catch((error) => {
      console.error("Error booking item:", error);
      throw error;
    });
};

export const unbookItem = (itemId) => {
  return axiosInstance
    .post(`/wishlist_items/${itemId}/unbook`, {
      wishlistItem: { booked_by_name: null },
    })
    .then(handleResponse)
    .catch((error) => {
      console.error("Error unbooking item:", error);
      throw error;
    });
};
