import React from "react";
import AnimatedCursor from "react-animated-cursor";

const Cursor = () => {
  return (
    <AnimatedCursor
      innerSize={8}
      outerSize={35}
      color="255, 255, 255"
      outerAlpha={0.2}
      innerScale={1}
      outerScale={1.5}
      outerStyle={{
        mixBlendMode: "exclusion",
      }}
    />
  );
};

export default Cursor;
