const paths = {
  index: "/",
  login: "/login",
  wishlists: "/wishlists",
  wishlist: "/wishlists/:id",
  butch: "/butch",
  live: "/live",
  paint: "/paint",
};
export default paths;
