import { axiosInstance } from "./axiosService";

export const getLogin = (email, password) => {
  const userParams = {
    user: {
      email,
      password,
    },
  };

  return axiosInstance.post("/users/log_in", userParams).catch((error) => {
    console.error("Error during login:", error);
    throw error;
  });
};
