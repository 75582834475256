import React from "react";
import styles from "./Login.module.scss";
import { useLoginPageHook } from "../../hooks/useLoginPageHook";

const LoginPage = () => {
  const {
    formState: { email, password },
    handleInputChange,
    handleSubmit,
    emailInputRef,
  } = useLoginPageHook();

  return (
    <div className={styles.loginForm}>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          id="email"
          name="email"
          className={styles.input}
          placeholder="Email"
          value={email}
          onChange={handleInputChange}
          ref={emailInputRef}
        />
        <input
          type="password"
          id="password"
          name="password"
          className={styles.input}
          placeholder="Password"
          value={password}
          onChange={handleInputChange}
        />
        <button type="submit" className={styles.loginForm__submit}>
          Sign in
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
