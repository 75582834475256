import React from "react";
import styles from "./Wish.module.scss";
import cx from "classnames";
import WishActions from "./WishActions";

const WishContent = ({
  item,
  bookingName,
  setBookingName,
  handleBooking,
  unbook,
  localId,
  isHide,
  copyToClipboard,
  handleSearch,
  handleKeyPress,
  data,
}) => {
  return (
    <div className={styles.column}>
      <div className={styles.row}>
        <span
          className={cx(styles.font, {
            [styles.del]: isHide && item.booked,
          })}
          onClick={() => {
            copyToClipboard(item.wish);
            handleSearch(item.wish);
          }}
        >
          {item.wish}
        </span>
        {localId?.toString() !== data?.user_id?.toString() && (
          <WishActions
            bookingName={bookingName}
            handleBooking={handleBooking}
            setBookingName={setBookingName}
            item={item}
            unbook={unbook}
            handleKeyPress={handleKeyPress}
          />
        )}
      </div>
    </div>
  );
};

export default WishContent;
