import { apiUrl } from "../utils/constants";
import { axiosInstance } from "./axiosService";

const handleResponse = (response) => {
  if (response && response.data && response.data.data) {
    return response.data.data;
  }
  throw new Error("Invalid response structure");
};

export const getMe = () => {
  return axiosInstance
    .get("/me")
    .then((response) => {
      const data = handleResponse(response);
      localStorage.setItem("id", data.id);
      return data;
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
      throw error;
    });
};

export const getServices = () => {
  return axiosInstance
    .get(`${apiUrl}/services`)
    .then((response) => {
      const services = handleResponse(response);
      const initialStatuses = services.reduce((acc, service) => {
        acc[service.name] = false;
        return acc;
      }, {});
      return { services, initialStatuses };
    })
    .catch((error) => {
      console.error("Error fetching services:", error);
      throw error;
    });
};
