import React from "react";
import styles from "./Wishlists.module.scss";
import { useWishlistsHook } from "../../hooks/useWishlistsHook";
import List from "./List";
import DataRenderer from "../../components/DataRenderer/DataRenderer";

const Wishlists = () => {
  const { toggleHide, isLoading, data, createNewWishlist, getListProps } =
    useWishlistsHook();
  return (
    <>
      <button className={styles.toggleHide} onClick={toggleHide}>
        Toggle Hide
      </button>
      <button
        className={styles.createList}
        onClick={createNewWishlist(false)}
        disabled={isLoading}
      >
        Создать вишлист
      </button>
      <button
        className={styles.createPrivateList}
        onClick={createNewWishlist(true)}
        disabled={isLoading}
      >
        Создать приватный вишлист
      </button>

      <div className={styles.wrapper}>
        <div className={styles.wishlist}>
          <DataRenderer data={data} Component={List} getProps={getListProps} />
        </div>
      </div>
    </>
  );
};

export default Wishlists;
