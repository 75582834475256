import React from "react";
import styles from "./Wish.module.scss";
import paths from "../paths";
import { useWishHook } from "../../hooks/useWishHook";
import WishContent from "./WishContent";
import DataRenderer from "../../components/DataRenderer/DataRenderer";

const Wish = () => {
  const {
    id,
    navigate,
    toggleHide,
    data,
    wishClass,
    isHide,
    localId,
    getWishContentProps,
  } = useWishHook();

  if (!id) {
    navigate(paths.wishlists);
  }

  return (
    <>
      {localId && (
        <button className={styles.toogleHide} onClick={toggleHide}>
          {isHide ? "Скрыть" : "Показать"}
        </button>
      )}
      <div className={styles.wrapper}>
        {data && (
          <div className={wishClass}>
            <span
              className={styles.name}
            >{`by ${data?.user_name || "Петрович"}`}</span>
            <span>{data?.name || ""}</span>
            <div className={styles.wishes}>
              <DataRenderer
                data={data?.items.data}
                Component={WishContent}
                getProps={getWishContentProps}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Wish;
