import React from "react";
import styles from "./Link.module.scss";
import { RiMobileDownloadFill } from "react-icons/ri";
import { DiAndroid, DiAppstore } from "react-icons/di";
import { HiOutlineLink } from "react-icons/hi";
import Popup from "reactjs-popup";

const CustomPopup = ({ iosUrl, androidUrl, otherUrls }) => {
  const renderLinkIcon = (key, value) => (
    <HiOutlineLink
      className={styles.link}
      key={key}
      onClick={() => window.open(value.toString())}
    />
  );

  return (
    <Popup
      trigger={
        <div className={styles.downloadIcon}>
          <RiMobileDownloadFill />
        </div>
      }
      position="right center"
    >
      <div className={styles.iconsRow}>
        {iosUrl && (
          <DiAppstore
            color="blue"
            cursor="pointer"
            className={styles.link}
            onClick={() => window.open(iosUrl)}
          />
        )}
        {androidUrl && (
          <DiAndroid
            color="green"
            cursor="pointer"
            className={styles.link}
            onClick={() => window.open(androidUrl)}
          />
        )}
        {otherUrls &&
          Object.entries(otherUrls)
            .slice(0, 3)
            .map(([key, value]) => renderLinkIcon(key, value))}
      </div>
    </Popup>
  );
};

export default CustomPopup;
