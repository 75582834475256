import React, { useState } from "react";
import styles from "./Wishlists.module.scss";

const List = ({
  el,
  isEdit,
  wishListIdToUpdate,
  newWishListName,
  setWishListIdToUpdate,
  setNewWishListName,
  isLoading,
  updateWishlistNameHandler,
  deleteExistingWish,
  newWish,
  setNewWish,
  updateExistingWishlist,
  deleteExistingWishlist,
  openWishlist,
  updateWishItem,
}) => {
  const [editingItemId, setEditingItemId] = useState(null);
  const [editedWishText, setEditedWishText] = useState("");
  const [editingOrderId, setEditingOrderId] = useState(null);
  const [editedOrderValue, setEditedOrderValue] = useState("");

  const handleEditClick = (item) => {
    setEditingItemId(item.id);
    setEditedWishText(item.wish);
  };

  const handleSaveEdit = (itemId) => {
    if (editedWishText.trim()) {
      updateWishItem(itemId, { wish: editedWishText });
      setEditingItemId(null);
    }
  };

  const handleCancelEdit = () => {
    setEditingItemId(null);
    setEditedWishText("");
  };

  const handleEditOrderClick = (item) => {
    setEditingOrderId(item.id);
    setEditedOrderValue(item.order !== undefined ? item.order.toString() : "");
  };

  const handleSaveOrderEdit = (itemId) => {
    if (editedOrderValue !== "") {
      const numValue = parseInt(editedOrderValue, 10);
      if (!isNaN(numValue)) {
        updateWishItem(itemId, { order: numValue });
      }
      setEditingOrderId(null);
    }
  };

  const handleCancelOrderEdit = () => {
    setEditingOrderId(null);
    setEditedOrderValue("");
  };

  return (
    <div className={styles.wishList}>
      <div className={styles.input}>
        {isEdit(el) && (
          <input
            type="text"
            placeholder="Новое имя вишлиста"
            value={wishListIdToUpdate === el.id ? newWishListName : ""}
            onChange={(e) => {
              setWishListIdToUpdate(el.id);
              setNewWishListName(e.target.value);
            }}
            disabled={isLoading}
          />
        )}
        {isEdit(el) && (
          <button
            onClick={() => updateWishlistNameHandler(el.id)}
            disabled={isLoading || !newWishListName.trim()}
          >
            Изменить имя
          </button>
        )}
      </div>
      <div className={styles.wishItems}>
        {el.items.data.map((item, index) => (
          <div className={styles.wishItem} key={item.id}>
            {editingItemId === item.id ? (
              <div className={styles.editWishForm}>
                <input
                  type="text"
                  value={editedWishText}
                  onChange={(e) => setEditedWishText(e.target.value)}
                  disabled={isLoading}
                  autoFocus
                />
                <div className={styles.editActions}>
                  <button
                    onClick={() => handleSaveEdit(item.id)}
                    disabled={isLoading || !editedWishText.trim()}
                  >
                    Сохранить
                  </button>
                  <button
                    className={styles.cancelButton}
                    onClick={handleCancelEdit}
                    disabled={isLoading}
                  >
                    Отмена
                  </button>
                </div>
              </div>
            ) : (
              <>
                <span className={styles.wishText}>{item.wish}</span>
                {isEdit(el) && (
                  <div className={styles.itemActions}>
                    <div className={styles.orderButtons}>
                      {editingOrderId === item.id ? (
                        <div className={styles.orderEditForm}>
                          <input
                            type="number"
                            value={editedOrderValue}
                            onChange={(e) =>
                              setEditedOrderValue(e.target.value)
                            }
                            disabled={isLoading}
                            autoFocus
                            min="0"
                            className={styles.orderInput}
                          />
                          <button
                            onClick={() => handleSaveOrderEdit(item.id)}
                            disabled={isLoading}
                            className={styles.smallButton}
                          >
                            ✓
                          </button>
                          <button
                            onClick={handleCancelOrderEdit}
                            disabled={isLoading}
                            className={styles.smallButton}
                          >
                            ✕
                          </button>
                        </div>
                      ) : (
                        <button
                          className={styles.orderEditButton}
                          onClick={() => handleEditOrderClick(item)}
                          disabled={isLoading}
                          title="Редактировать порядок"
                        >
                          #{item.order !== undefined ? item.order : index}
                        </button>
                      )}
                    </div>
                    <button
                      className={styles.editButton}
                      onClick={() => handleEditClick(item)}
                      disabled={isLoading}
                    >
                      Изменить
                    </button>
                    <button
                      className={styles.deleteButton}
                      onClick={() => deleteExistingWish(item.id, item.wish)}
                      disabled={isLoading}
                    >
                      Удалить
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
      <div className={styles.actionButtons}>
        <div className={styles.addWishForm}>
          {isEdit(el) && (
            <>
              <input
                type="text"
                placeholder="Введите ваш виш"
                value={wishListIdToUpdate === el.id ? newWish : ""}
                onChange={(e) => {
                  setWishListIdToUpdate(el.id);
                  setNewWish(e.target.value);
                }}
                disabled={isLoading}
              />
              <button
                onClick={updateExistingWishlist}
                disabled={isLoading || !newWish.trim()}
              >
                Добавить
              </button>
            </>
          )}
        </div>
        <div className={styles.listActions}>
          {isEdit(el) && (
            <button
              className={styles.deleteButton}
              onClick={() => deleteExistingWishlist(el.id)}
              disabled={isLoading}
            >
              Удалить список
            </button>
          )}
          <button
            className={styles.shareButton}
            onClick={() => openWishlist(el.id)}
            disabled={isLoading}
          >
            Поделиться
          </button>
        </div>
      </div>
    </div>
  );
};

export default List;
