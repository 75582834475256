import Login from "../pages/Login/Login";
import paths from "../pages/paths";
import MainPage from "../pages/MainPage/MainPage";
import Wishlists from "../pages/Wishists/Wishlists";
import Wish from "../pages/Wish/Wish";
import SketchfabEmbed from "../components/3D/Model";
import Live from "../pages/Live/Live";
import Paint from "../pages/Paint/Paint";
import Fabric from "../pages/Fabric/Fabric";

const routesConfig = [
  {
    path: paths.index,
    component: MainPage,
    requiresAuth: true,
  },
  {
    path: paths.login,
    component: Login,
    requiresAuth: false,
  },
  {
    path: paths.wishlists,
    component: Wishlists,
    requiresAuth: false,
  },
  {
    path: paths.wishlist,
    component: Wish,
    requiresAuth: false,
  },
  {
    path: paths.butch,
    component: SketchfabEmbed,
    requiresAuth: false,
  },
  {
    path: paths.live,
    component: Live,
    requiresAuth: false,
  },
  {
    path: paths.paint,
    component: Fabric,
    requiresAuth: false,
  },
];

export default routesConfig;
