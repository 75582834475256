import React from "react";
import animationData from "../../animation.json";
import Lottie from "lottie-react";
import styles from "./styles.module.scss";

const Anime = () => {
  return (
    <Lottie
      className={styles.wrapper}
      animationData={animationData}
      loop={true}
    />
  );
};

export default Anime;
