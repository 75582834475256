import { useEffect, useRef, useState } from "react";
import { getLogin } from "../services/authService";
import paths from "../pages/paths";
import { NotificationManager } from "react-notifications";
import useNavigation from "./useNavigation";

export const useLoginPageHook = () => {
  const [formState, setFormState] = useState({ email: "", password: "" });
  const { redirectTo } = useNavigation();
  const emailInputRef = useRef(null);

  useEffect(() => {
    emailInputRef.current.focus();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = formState;
    if (email && password) {
      setFormState({ email: "", password: "" });
      try {
        await getLogin(email, password);
        redirectTo(paths.index);
      } catch (error) {
        NotificationManager.error("Не прокатило, не фортануло", "", 3000);
      }
    }
  };

  return {
    formState,
    handleInputChange,
    handleSubmit,
    emailInputRef,
  };
};
