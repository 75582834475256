import React from "react";
import { NotificationContainer } from "react-notifications";
import Cursor from "../Cursor/Cursor";
import { isDesktop } from "react-device-detect";

const AppExtras = () => {
  return (
    <>
      <NotificationContainer />
      {isDesktop && <Cursor />}
    </>
  );
};

export default AppExtras;
